import React from "react"
import { FaqIcon } from "./Icons"

export const FaqItems = ({ items, activeIndex, onClick }) => {
  const onTitleClick = index => {
    onClick(index === activeIndex ? null : index)
  }

  return (
    <>
      {items.map((item, index) => {
        const active = index === activeIndex
        return (
          <div className="faq__item" key={item.question + index}>
            <div
              className="faq__item__title-container"
              onClick={() => onTitleClick(index)}
              onKeyDown={() => onTitleClick(index)}
              role="presentation"
            >
              <h3 className={`faq__item__title ${active ? "active" : ""}`}>
                {item.question}
              </h3>
              <FaqIcon
                className={`faq__item__arrow ${active ? "active" : ""}`}
              />
            </div>
            <div dangerouslySetInnerHTML={{ __html: item.answer }} className={`faq__item__content ${active ? "active" : ""}`} />
          </div>
        )
      })}
    </>
  )
}
