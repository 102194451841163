import React from "react"

export const BoxesSection = ({ title, subtitle, boxes, itemsPerRow, bgColor }) => {
  return (
    <section style={{ backgroundColor: bgColor }} className="dev-ops-offer__container">
      <div className="container">
        <div className="dev-ops-offer">
          {title ? <h2 className="dev-ops-offer__title">{title}</h2> : ''}
          {subtitle ? <h3 className="dev-ops-offer__subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} /> : ''}
          <div className={`customised-software-solutions__items ${itemsPerRow === 2 ? 'customised-software-solutions__items__two-per-row' : 'customised-software-solutions__items__three-per-row'}`}>
            {boxes.map((item) => (
              <div
                key={item.id}
                className="customised-software-solutions__item"
              >
                <img
                  src={item.icon.url}
                  className="customised-software-solutions__item__image"
                  alt="Devops offer card icon"
                />
                <h3
                  className="customised-software-solutions__item__title"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <div dangerouslySetInnerHTML={{ __html: item.content }} className="customised-software-solutions__item__text" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section >
  )
}
