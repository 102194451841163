import React from "react"
import CustomLink from "../../Link";
import arrow from '../../../img/arrow.png'

export const SingleCaseSection = ({ title, linkText, image, hashtags, lang, caseBackgroundColor, caseTitle, caseDescription, customerCase, sectionBackgroundColor }) => {
  const totalCharHashtags = hashtags
    .slice(0, 3)
    .map(str => str.hashtag.length)
    .reduce((acc, val) => acc + val, 0)

  const hashtagsArr =
    totalCharHashtags > 35
      ? hashtags.slice(0, 2)
      : hashtags.slice(0, 3)
  return (
    <section style={{ backgroundColor: sectionBackgroundColor }} className={`digital-solution__section digital-solution__section__cross-platform-solutions`}>
      <div className="container">
        <div className="cross-platform-solutions">
          <div className="digital-solution__content cross-platform-solutions__content">
            <h2 className="digital-solution__content__title cross-platform-solutions__title">
              {title}
            </h2>
            <div className="digital-solution__content__project" style={{ background: caseBackgroundColor }}>
              <div className="digital-solution__content__project__content">
                <p className="digital-solution__content__project__title">{caseTitle}</p>
                <div className="digital-solution__content__project__description" dangerouslySetInnerHTML={{ __html: caseDescription }} />
                <div className="digital-solution__content__project__tags-container">
                  {hashtagsArr.map(item => (
                    <p key={item.hashtag}>{`${item.hashtag}`}</p>
                  ))}
                </div>
                <CustomLink to={`/our-work/${customerCase.slug}/`} className="btn btn__secondary" lang={lang}>
                  {linkText}
                  <img height={"13px"} width={"8px"} style={{ marginLeft: '12px' }} src={arrow} alt={"arrow"} />
                </CustomLink>
              </div>
              <img
                className="cross-platform-solutions__icon"
                src={image.url}
                alt="Cross platform"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}