import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import LandingContent from "../components/Landing/LandingContent"
import { HeroSection } from "../components/Landing/components"
import { FaqSection } from "../components/ServicePageSections/FaqSection"
import { FAQSchemaGenerate } from "../schemas-org"


const Landing = props => {
  const lang = props.pageContext.locale
  const landings = props.data.landing.nodes
  const landing = landings.find(post => post.locale === lang)
  const { slug, seo, heroDescription, heroBackgroundImage, title, sections, showFaq, faq } = landing

  return (
    <Layout whiteNav isHome lang={lang} location={props.location} showContact={false}>
      <Seo
        title={seo?.title ?? ""}
        slug={slug}
        meta={seo?.description ?? ""}
        image={seo && seo.image && seo.image.url || ""}
        schemaMarkup={showFaq && FAQSchemaGenerate(faq[0].faqItems)}
        description={seo?.description ?? ""}
      />
      <article className="landing">
        <HeroSection
          description={heroDescription}
          imgSrc={heroBackgroundImage}
          title={title}
        />
        <LandingContent
          sections={sections}
          lang={lang}
        />
        {showFaq && (
          <FaqSection
            items={faq[0].faqItems}
            title={faq[0].title}
            secondTitle={faq[0].faqMoreQuestions}
            secondSubtitle={faq[0].faqConsultation}
            lang={lang}
          />
        )}
      </article>    </Layout>
  )
}

export const query = graphql`
  query SingleArticle($slug: String) {
    landing: allDatoCmsLandingPage(filter: {slug: {eq: $slug}}) {
      nodes {
        slug
        locale
        title
        heroDescription
        heroBackgroundImage {
          url
        }
        menuTitle
        seo {
          description
          title
          image {
            url
          }
        }
        showFaq
        faq {
          title
          faqItems {
            question
            answer
          }
          faqMoreQuestions
          faqConsultation
        }
        sections {
          ... on DatoCmsLandingQuoteSection {
            id
            backgroundColor {
              hex
            }          
            quoteAuthor
            quoteAuthorRole
            quoteDescription
            header
            paragraphs {
              paragraphText
              paragraphTitle
              id
            }
          }
          ... on DatoCmsLandingContentWithIconSection {
            id
            backgroundColor {
              hex
            }   
            header
            icon {
              url
            }
            paragraphs {
              paragraphTitle
              paragraphText
              id
            }
          }
          ... on DatoCmsLandingSimpleContentSection {
            id
            backgroundColor {
              hex
            }  
            header
            summationBlockContentAfter
            summationBlockContentBefore
            summationBlockHeader
            paragraphs {
              paragraphText
              paragraphTitle
              id
            }
            summationBlockItems {
              text
              id
              icon {
                url
              }
            }
          }
          ... on DatoCmsLandingBoxesSection {
            id
            backgroundColor {
              hex
            } 
            subheader
            header
            itemsPerRow
            boxes {
              icon {
                url
              }
              id
              title
              content
            }
          }
          ... on DatoCmsLandingSectionWithNumbering {
            id
            backgroundColor {
              hex
            }       
            header
            description
            icon {
              url
            }
            paragraphs {
              id
              paragraphText
              paragraphTitle
            }
          }
          ... on DatoCmsCallToActionSmall {
            id
            title
            buttonTitle
          }
          ... on DatoCmsCallToActionBig {
            id
            buttonTitle
            description
            title
          }
          ... on DatoCmsImageSection {
            id
            image {
              url
              gatsbyImageData
              alt
            }
          }
          ... on DatoCmsLandingCustomerCasesSection {
            id
            backgroundColor {
              hex
            } 
            title
            cases {
              title
              slug
              mainImage {
                alt
                gatsbyImageData(height: 260, layout: FIXED)
                url
              }
              projectBackground {
                hex
              }
              hashtags {
                hashtag
              }
            }
          }
          ... on DatoCmsLandingSingleCustomerCaseSection {
            id
            sectionBackgroundColor {
              hex
            } 
            caseDescription
            caseTitle
            customerCase {
              slug
            }
            image {
              url
            }
            linkText
            title
            tags {
              hashtag
            }
            caseBackgroundColor {
              hex
            }
          }
          ... on DatoCmsLandingBlogArticlesSection {
            id
            title
            backgroundColor {
              hex
            } 
            blogs {
              title
              slug
              articleDate
              categories {
                id
                slug   
                name      
              }
              image {
                gatsbyImageData
                alt
              }
            }
          }
          ... on DatoCmsLandingTestimonialSection {
            id
            title
            backgroundColor {
              hex
            } 
            customers {
              company
              id
              name
              role
              quote
              photo {
                url
                alt
                gatsbyImageData
              }
            }
          }
          ... on DatoCmsLandingPhaseSection {
            id
            firstStepBackground
            mainTitle
            steps {
              id
              itemTitle
              image {
                alt
                url
              }
              paragraphs {
                id
                paragraphText
                paragraphTitle
              }
            }
          }
        }
      }
    }
  }
`

export default Landing
