import React from "react"
import CustomLink from "../../Link"
import { BlogCard } from "../../BlogCard"
import arrow from "../../../img/arrow.png"
import Slider from "react-slick"

export const BlogArticlesSection = ({ title, blogs, lang, backgroundColor }) => {
  const getSlidesToShow = () => {
    if (blogs.length < 3) return 2
    else return 3
  }
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 600,
    arrow: true,
    slidesToShow: getSlidesToShow(),
    dotsClass: "custom-dots",
    responsive: [
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: blogs.length < 2 ? false : true,
          dots: true,
        },
      },
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: blogs.length < 2 ? false : true,
          dots: true,
        },
      },
    ],
  }
  return (
    <section className="more-in-blog__container" style={{ backgroundColor: backgroundColor }}>
      <div className="container">
        <div className="more-in-blog">
          <h2 className="more-in-blog__title">{title}</h2>
          <div className="more-in-blog__blogs">
            <Slider {...settings}>
              {blogs.map((blog, index) => (
                <BlogCard lang={lang} key={index} blog={blog} />
              ))}
            </Slider>
          </div>
          <div className="more-in-blog__btn__container">
            <CustomLink lang={lang} to="/blog/" className="btn btn__secondary">
              {lang === "en" ? "See all articles" : "Alle Beiträge"}
              <img height={"13px"} width={"8px"} src={arrow} alt={"arrow"} />
            </CustomLink>
          </div>
        </div>
      </div>
    </section>
  )
}