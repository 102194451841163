import React from 'react';
import CustomLink from '../../Link';

export const CallToActionBig = ({ title, subtitle, buttonText, lang }) => {
  return (
    <section className="intrigued__container">
      <div className="container">
        <div className="intrigued">
          <h2 className="digital-solution__content__title intrigued__title">
            {title}
          </h2>
          <div
            className="intrigued__subtitle"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
          <div className="btn__primary--animation intrigued__button">
            <CustomLink
              className="btn btn__primary"
              to={"/contact/"}
              lang={lang}
            >
              {buttonText}
            </CustomLink>
          </div>
        </div>
        <div className="intrigued__bg__gradient" />
        <div className="intrigued__particle" />
        <div className="intrigued__particle__gradient" />
        <div className="intrigued__particles">
          <div className="intrigued__particles__item-1" />
          <div className="intrigued__particles__item-2" />
        </div>
      </div>
    </section>
  )
}