import React from "react"

export const HeroSection = ({ title, description, imgSrc }) => {
  return (
    <section style={{ backgroundImage: `url(${imgSrc.url})` }} className="services-hero__container background__black">
      <div className="container">
        <div className="services-hero__content">
          <h1
            className="services-hero__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className="services-hero__subtitle" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div className="services-hero__particle" />
        <div className="services-hero__particle__gradient" />
      </div>
    </section>
  )
}