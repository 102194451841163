import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import { OurWorkCard } from "../components/OurWorkCard"

const query = graphql`
  query WorkDE {
    datoCmsProjectsPage(locale: { eq: "de" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      heading
      description
      buttonCta
      callToAction
    }
    allDatoCmsProject(
      filter: { locale: { eq: "de" }, showProject: { ne: false } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        mainImage {
          gatsbyImageData
          alt
        }
        projectBackground {
          rgb
        }
        slug
        title
        description
        customer
        hashtags {
          hashtag
        }
      }
    }
  }
`

const OurWork = props => {
  const lang = props.pageContext.langKey
  const data = useStaticQuery(query)
  const { seo, heading, buttonCta, callToAction } = data.datoCmsProjectsPage
  const projects = data.allDatoCmsProject.nodes

  return (
    <Layout
      lang={lang}
      location={props.location}
      buttonCta={buttonCta}
      callToAction={callToAction}
    >
      <Seo
        title={seo.title ?? ""}
        description={seo.description ?? ""}
        meta={seo.description ?? ""}
        image={seo && seo.image && seo.image.url}
        slug="our-work"
      />
      <div className="work">
        <div className="container">
          <h1
            className="work__heading"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          <div className="work__projects">
            <div className="work__content">
              {projects.map((project, index) => (
                <div key={project.title + index}>
                  <OurWorkCard project={project} lang={lang} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OurWork
