import React from "react"

export const SummaryBlogSection = ({ summaryData, lang }) => {
  const title = lang === 'en' ? 'Key insights from this article' : 'Die wichtigsten Erkenntnisse dieses Beitrags'
  return (
    <section id={title} className="summary-blog">
      <h3 className="summary-blog__title" ><span>{title}</span> </h3>
      {summaryData[0].items.map((item, index) => (
        <div className="summary-blog__item" key={index}>
          <h4 className="summary-blog__item--title">{item.title}</h4>
          <p>{item.description}</p>
        </div>
      ))}
    </section >
  )
}
