import React from "react"
import CustomLink from "../../Link"
import { ProjectCard } from "../../ProjectCard"

export const CasesSection = ({ title, cases, lang, bgColor }) => {
  return (
    <section style={{ backgroundColor: bgColor }} className={`solutions-we-build__section faq-hidden`}>
      <div className="container">
        <div className="solutions-we-build">
          <h2 className="solutions-we-build__title">{title}</h2>
          <div className="solutions-we-build__projects">
            {cases.slice(0, 3).map((project, index) => (
              <div key={project.title + index}>
                <ProjectCard project={project} lang={lang} />
              </div>
            ))}
          </div>
          <CustomLink to="/our-work/" className="btn btn__secondary" lang={lang}>
            {`${lang === "en" ? "All projects" : "Alle Projekte"} >`}
          </CustomLink>
        </div>
      </div>
    </section>
  )
}