import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "./Link"

export const ProjectCard = ({ lang, project }) => {
  const totalCharHashtags = project.hashtags
    .slice(0, 3)
    .map(str => str.hashtag.length)
    .reduce((acc, val) => acc + val, 0)

  const hashtags =
  totalCharHashtags > 35
      ? project.hashtags.slice(0, 2)
      : project.hashtags.slice(0, 3)
  return (
    <Link
    to={`/our-work/${project.slug}/`}
    className="project-card__link"
    lang={lang}
  >
    <div className="project-card__container">
      <div
        className="project-card__image-container"
        style={{ backgroundColor: project.projectBackground.hex }}
      >
        <GatsbyImage
          image={project.mainImage.gatsbyImageData}
          className="project-card__image"
          alt={project.mainImage.alt} />
      </div>
      <div className="project-card__content">
        <div className="project-card__tags-container">
          {hashtags.map(item => (
            <p key={item.hashtag}>{`${item.hashtag}`}</p>
          ))}
        </div>
        <h3 className="project-card__title">{project.title}</h3>
        <span
          className="btn btn__secondary"
        >
          {`${lang === "en" ? "Learn more" : "Mehr erfahren"} >`}
        </span>
      </div>
    </div>
    </Link>
  );
}
