import React from "react"

const formatNumber = (numb) => numb > 9 ? numb : `0${numb}`

export const PhasesSection = ({ title, phases, firstStepBackground }) => {
  return (
    <section className="phases-section">
      <div className="phases-section__content">
        <h2 className="phases-section__content__title--mobile">
          {title}
        </h2>
        <div className={`${firstStepBackground ? 'odd-wrapper' : 'even-wrapper'}`}>
          {phases.map((item, index) => (
            <div key={item.id} className={`phases-section__content__phase ${index % 2 === 1 ? 'phases-section__content__phase__reverse' : ''}`}>
              <div className="phases-section__content__phase__content-container">
                <div className="phases-section__content__phase__image-container">
                  <img
                    alt={item.image.alt}
                    src={item.image.url}
                  />
                </div>
                <div className="phases-section__content__phase__text-container">
                  {title && index === 0 ? (
                    <h2 className="phases-section__content__title">
                      {title}
                    </h2>
                  ) : ''}
                  <div className="phases-section__content__phase__title-container">
                    <h3>{item.itemTitle}</h3>
                    <span className={`phases-section__content__phase__number ${index % 2 === 1 ? 'reverse' : ''}`}>{formatNumber(index + 1)}</span>
                  </div>
                  <div>
                    <ul>
                      {item.paragraphs.map((_item) => (
                        <li key={_item.id}>
                          <p>{_item.paragraphTitle}</p>
                          <div dangerouslySetInnerHTML={{ __html: _item.paragraphText }} />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}