import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Link from "./Link"

export const OurWorkCard = ({ lang, project }) => {
  const totalCharHashtags = project.hashtags
    .slice(0, 3)
    .map(str => str.hashtag.length)
    .reduce((acc, val) => acc + val, 0)

  const hashtags =
    totalCharHashtags > 35
      ? project.hashtags.slice(0, 2)
      : project.hashtags.slice(0, 3)

  return (
    <Link
      to={`/our-work/${project.slug}/`}
      className="our-work-card__link"
      lang={lang}
    >
      <div className="our-work-card__container">
        <div
          className="our-work-card__image-container"
          style={{ backgroundColor: project.projectBackground.rgb }}
        >
          <GatsbyImage
            image={project.mainImage.gatsbyImageData}
            className="our-work-card__image"
            alt={project.mainImage.alt}
          />
        </div>
        <div className="our-work-card__content">
          <div className="our-work-card__tags-container">
            {hashtags.map(item => (
              <p
                key={item.hashtag}
                className="our-work-card__tag header-tags"
              >{`${item.hashtag}`}</p>
            ))}
            <h6 className="our-work-card__customer">{project.customer}</h6>
          </div>
          <h3 className="our-work-card__title">{project.title}</h3>
          <div className="our-work-card__butt">
            <span className="btn__secondary">
              {`${lang === "en" ? "Learn more" : "Mehr erfahren"} >`}
            </span>
          </div>
          <p className="our-work-card__desc">{project.description}</p>
          <div className="our-work-card__tags-all">
            {project.hashtags.map(item => (
              <p
                key={item.hashtag}
                className="our-work-card__tag"
              >{`${item.hashtag}`}</p>
            ))}
          </div>
        </div>

      </div>
    </Link>
  )
}
