import React from 'react'
import { PhasesSection } from '../PhasesSection'
import { BoxesSection, SingleCaseSection, CallToActionBig, CallToActionSmall, CasesSection, ContentWithIconSection, ImageSection, QuoteSection, SectionWithNumbering, SimpleContentSection, BlogArticlesSection, TestemonialSection } from './components';

const LandingContent = ({
  sections,
  lang
}) => {
  const renderContent = () => {
    return sections.filter(section => section.id).map(section => {
      if (section.id.startsWith('DatoCmsLandingQuoteSection')) {
        return (
          <QuoteSection
            author={section.quoteAuthor}
            authorPosition={section.quoteAuthorRole}
            comment={section.quoteDescription}
            title={section.header}
            paragraphs={section.paragraphs}
            bgColor={section.backgroundColor?.hex || ''}
          />
        );
      } else if (section.id.startsWith('DatoCmsLandingContentWithIconSection')) {
        return (
          <ContentWithIconSection
            image={section.icon.url}
            title={section.header}
            paragraphs={section.paragraphs}
            bgColor={section.backgroundColor?.hex || ''}
          />
        );
      } else if (section.id.startsWith('DatoCmsLandingSimpleContentSection')) {
        return (
          <SimpleContentSection
            title={section.header}
            paragraphs={section.paragraphs}
            contentAfter={section.summationBlockContentAfter}
            contentBefore={section.summationBlockContentBefore}
            summationBlockHeader={section.summationBlockHeader}
            items={section.summationBlockItems}
            bgColor={section.backgroundColor?.hex || ''}
          />
        )
      } else if (section.id.startsWith('DatoCmsLandingBoxesSection')) {
        return (
          <BoxesSection
            title={section.header}
            subtitle={section.subheader}
            boxes={section.boxes}
            itemsPerRow={section.itemsPerRow}
            bgColor={section.backgroundColor?.hex || ''}
          />
        )
      } else if (section.id.startsWith('DatoCmsLandingSectionWithNumbering')) {
        return (
          <SectionWithNumbering
            icon={section.icon}
            title={section.header}
            subTitle={section.description}
            items={section.paragraphs}
            bgColor={section.backgroundColor?.hex || ''}
          />
        )
      } else if (section.id.startsWith('DatoCmsCallToActionSmall')) {
        return (
          <CallToActionSmall
            lang={lang}
            title={section.title}
            buttonText={section.buttonTitle}
          />
        )
      } else if (section.id.startsWith('DatoCmsCallToActionBig')) {
        return (
          <CallToActionBig
            lang={lang}
            title={section.title}
            subtitle={section.description}
            buttonText={section.buttonTitle}
          />
        )
      } else if (section.id.startsWith('DatoCmsImageSection')) {
        return (
          <ImageSection
            image={section.image}
          />
        )
      } else if (section.id.startsWith('DatoCmsLandingCustomerCasesSection')) {
        return (
          <CasesSection
            cases={section.cases}
            title={section.title}
            lang={lang}
            bgColor={section.backgroundColor?.hex || ''}
          />
        )
      } else if (section.id.startsWith('DatoCmsLandingSingleCustomerCaseSection')) {
        return (
          <SingleCaseSection
            lang={lang}
            caseBackgroundColor={section.caseBackgroundColor?.hex || ''}
            caseDescription={section.caseDescription}
            caseTitle={section.caseTitle}
            customerCase={section.customerCase}
            hashtags={section.tags}
            image={section.image}
            linkText={section.linkText}
            title={section.title}
            sectionBackgroundColor={section.sectionBackgroundColor?.hex || ''}
          />
        )
      } else if (section.id.startsWith('DatoCmsLandingBlogArticlesSection')) {
        return (
          <BlogArticlesSection
            backgroundColor={section.backgroundColor?.hex || ''}
            blogs={section.blogs}
            title={section.title}
            lang={lang}
          />
        )
      } else if (section.id.startsWith('DatoCmsLandingTestimonialSection')) {
        return (
          <TestemonialSection
            backgroundColor={section.backgroundColor?.hex || ''}
            title={section.title}
            slider={section.customers}
          />
        )
      } else if (section.id.startsWith('DatoCmsLandingPhaseSection')) {
        return (
          <PhasesSection
            phases={section.steps}
            title={section.mainTitle}
            firstStepBackground={section.firstStepBackground}
          />
        )
      }
    })
  };

  return (
    <>
      {renderContent()}
    </>
  )

}

export default LandingContent