import React from "react"
import Link from '../../Link';
import CtaBg from "../../../img/cta-blog-bg.png"
import CTABgMobile from '../../../img/contact_bg_mobile.webp'
import useWindowWidth from "../../../helpers/useWindowWidth"



export const CTABlogSection = ({ ctaData, lang }) => {
  const { ctaText, buttonName, buttonLink } = ctaData;
  const window = useWindowWidth()

  return (
    <section className="cta-blog ">
      <div className='contact__teaser--bg' style={{
        backgroundImage: `url(${window.width < 768 ? CTABgMobile : CtaBg})`,
      }}></div>
      <div className="cta-blog__text" dangerouslySetInnerHTML={{ __html: ctaText }} />
      <div className="contact__teaser--cta">
        <Link lang={lang} className="btn btn__primary" to={buttonLink}>
          {buttonName}
        </Link>
      </div>
    </section >
  )
}
