import React from 'react';
import CTABg from '../../../img/contact_bg.webp'
import CTABgMobile from '../../../img/contact_bg_mobile.webp'
import CustomLink from '../../Link';
import useWindowWidth from '../../../helpers/useWindowWidth';

export const CallToActionSmall = ({ title, buttonText, lang }) => {
  const window = useWindowWidth()

  return (
    <section className="contact__teaser background__black">
      <div className='contact__teaser--bg' style={{
        backgroundImage: `url(${window.width < 768 ? CTABgMobile : CTABg})`,
      }}></div>
      <div className="container">
        <div className="contact__teaser--container">
          <div className="contact__teaser--text" dangerouslySetInnerHTML={{ __html: title }} />
          <div className="contact__teaser--cta">
            <CustomLink lang={lang} className="btn btn__primary" to='/contact/'>
              {buttonText}
            </CustomLink>
          </div>
        </div>
      </div>
    </section>
  )
}