import React from "react"

export const CalloutBlogSection = ({ calloutData }) => {
  const { title, calloutContent } = calloutData;
  return (
    <section className="callout-blog container">
      <div className="callout-blog__title">{title}</div>
      <div className="callout-blog__content" dangerouslySetInnerHTML={{ __html: calloutContent }} />
    </section >
  )
}
