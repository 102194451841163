import React from "react"
import { QuoteLeftIcon, QuoteRightIcon } from "../../Icons"
import parse from "html-react-parser";


export const QuoteSection = ({ title, paragraphs, comment, author, authorPosition, bgColor }) => {
  return (
    <section style={{ backgroundColor: bgColor }} className={`quote-section__container`}>
      <div className="container quote-section">
        <div className="digital-solution__content quote-section__content">
          <h2
            className="digital-solution__content__title"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h2>
          <div className="quote-section__items">
            {paragraphs.map(item => (
              <div className="quote-section__item" key={item.id}>
                <div className="quote-section__item__text">
                  <span class="color__blue">{item.paragraphTitle}</span>
                  <div>{parse(item.paragraphText)}</div>
                </div>
              </div>
            ))}

          </div>
        </div>
        <div className="quote-section__comment">
          <div className="quote-section__comment__text-block">
            <QuoteLeftIcon />
            <div className="quote-section__comment__text">{comment}</div>
            <QuoteRightIcon />
          </div>
          <div className="quote-section__comment__content">
            <h4 className="quote-section__comment__author">{author}</h4>
            <p className="quote-section__comment__position">{authorPosition}</p>
          </div>
          <div className="quote-section__comment__triangle" />
        </div>
      </div>
      <div className="quote-section__particles">
        <div className="quote-section__particles__item" />
        <div className="quote-section__particles__item_white" />
      </div>
    </section >
  )
}
