import React from "react"

export const ContentWithIconSection = ({ title, paragraphs, image, bgColor }) => {
  return (
    <section style={{ backgroundColor: bgColor }} className={`digital-solution__section digital-solution__section__cross-platform-solutions`}>
      <div className="container">
        <div className="cross-platform-solutions">
          <div className="digital-solution__content cross-platform-solutions__content">
            <h2 className="digital-solution__content__title cross-platform-solutions__title">
              {title}
            </h2>
            {paragraphs.map(item => (
              <div
                dangerouslySetInnerHTML={{
                  __html: `
                  <span class="color__blue">${item.paragraphTitle}</span></br>
                  ${item.paragraphText} 
                ` }}
                className="cross-platform-solutions__item"
              ></div>
            ))}

          </div>
          <img
            className="cross-platform-solutions__icon"
            src={image}
            alt="Cross platform"
          />
        </div>
      </div>
    </section>
  );
}
