import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

export const ImageSection = ({ image }) => {
  return (
    <section className="expect__container">
      {image && (
        <div className="expect__image-block">
          <GatsbyImage
            image={image.gatsbyImageData}
            className="expect__image"
            alt={image.alt}
          />
        </div>
      )}
    </section>
  )
}