import React, { useEffect, useRef, useState } from 'react'
import SliderBg from "../../../img/slider_bg.png"
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick"
import useWindowWidth from '../../../helpers/useWindowWidth';
import { SliderArrow, QuoteIconTop, QuoteIconBottom } from "../../../components/Icons"

export const TestemonialSection = ({ title, slider, backgroundColor }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 600,
  }
  const window = useWindowWidth()
  const totalSlides = slider.length
  const [slideIndex, setSlideIndex] = useState(0)
  const sliderRef = useRef(null)
  const prevSlideHandler = () => {
    const currentSlide = slideIndex
    const nextSlide = currentSlide === 0 ? totalSlides - 1 : currentSlide - 1
    setSlideIndex(nextSlide)
  }
  const nextSlideHandler = () => {
    const currentSlide = slideIndex
    const nextSlide = currentSlide === totalSlides - 1 ? 0 : currentSlide + 1
    setSlideIndex(nextSlide)
  }
  useEffect(() => {
    sliderRef.current.slickGoTo(slideIndex)
  }, [slideIndex])

  const swipeHandler = direction => {
    if (direction === "right") {
      prevSlideHandler()
    } else {
      nextSlideHandler()
    }
  }

  return (
    <div style={{ backgroundColor: backgroundColor }} className="home__companies">
      <div className="container">
        <h2
          className="home__companies--heading"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          className="home__companies--slider background__grey"
          style={{
            backgroundImage: `url(${window.width < 768 ? "" : SliderBg})`,
          }}
        >
          <div className="companies__slider">
            <div className="companies__slider--arrows">
              <div
                className="arrow prev"
                onClick={prevSlideHandler}
                role="button"
                tabIndex={0}
                aria-hidden
              >
                <SliderArrow />
              </div>
              <div
                className="arrow next"
                onClick={nextSlideHandler}
                role="button"
                tabIndex={0}
                aria-hidden
              >
                <SliderArrow />
              </div>
            </div>
            <Slider
              {...settings}
              ref={sliderRef}
              onSwipe={e => swipeHandler(e)}
            >
              {slider.map((slide) => (
                <div className="slide" key={slide.id}>
                  <div className="companies__slide">
                    <div className="companies__slide--about">
                      <div className="companies__slide--image">
                        <GatsbyImage
                          image={{
                            ...slide.photo.gatsbyImageData,
                            height: 130,
                            width: 130,
                            objectFit: "contain",
                          }}
                          alt={slide.photo.alt}
                          title={slide.photo.alt}
                          className="image" />
                      </div>
                      <div className="companies__slide--text">
                        <h4
                          className="companies__slide--heading"
                          dangerouslySetInnerHTML={{ __html: slide.name }}
                        />
                        <p
                          dangerouslySetInnerHTML={{ __html: `${slide.role} ${slide.company}` }}
                        />
                      </div>
                    </div>
                    <div className="companies__slide--content">
                      <QuoteIconTop />
                      <div dangerouslySetInnerHTML={{ __html: slide.quote }} />
                      <QuoteIconBottom />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="companies__slider--navigation">
              {slider.map((slide, index) => (
                <div
                  key={index}
                  className={`dot ${slideIndex === index ? "active" : ""}`}
                  onClick={() => setSlideIndex(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}