import React, { useState } from "react"
import Link from "../../components/Link"
import { FaqItems } from "../FaqItems"

export const FaqSection = ({
  items,
  title,
  secondTitle,
  secondSubtitle,
  lang,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <section className="faq__container">
      <div className="container">
        <div className="faq">
          <h2 className="digital-solution__content__title faq__title">
            {title}
          </h2>
          <FaqItems
            items={items}
            activeIndex={activeIndex}
            onClick={setActiveIndex}
          />
          <h3 className="faq__question">{secondTitle}</h3>
          <p className="faq__consultation">{secondSubtitle}</p>
          <div className="btn__primary--animation">
            <Link
              className="btn btn__primary"
              to={"/contact/"}
              lang={lang}
            >
              {lang === "en"?"Arrange a consultation":"Beratung buchen"}
            </Link>
          </div>
        </div>
      </div>
      <div className="faq__particles">
        <div className="faq__particles__item" />
        <div className="faq__particles__item_white" />
      </div>
    </section>
  )
}
