import React from "react"
import phase_icon_1 from "../../../img/phases-of-software_development_id_1.png"
import phase_icon_2 from "../../../img/phases-of-software_development_id_2.png"
import phase_icon_3 from "../../../img/phases-of-software_development_id_3.png"
import phase_icon_4 from "../../../img/phases-of-software_development_id_4.png"
import phase_icon_5 from "../../../img/phases-of-software_development_id_5.png"
import phase_icon_6 from "../../../img/phases-of-software_development_id_6.png"
import phase_icon_7 from "../../../img/phases-of-software_development_id_7.png"
import phase_icon_8 from "../../../img/phases-of-software_development_id_8.png"
import phase_icon_9 from "../../../img/phases-of-software_development_id_9.png"
import phase_icon_10 from "../../../img/phases-of-software_development_id_10.png"

export const SectionWithNumbering = ({ title, subTitle, items, icon, bgColor }) => {
  const numberImages = [phase_icon_1, phase_icon_2, phase_icon_3, phase_icon_4, phase_icon_5, phase_icon_6, phase_icon_7, phase_icon_8, phase_icon_9, phase_icon_10]

  return (
    <section style={{ backgroundColor: bgColor }} className={`expect__container`}>
      <div className="container">
        <div className="expect">
          <h2 className="consulting__page__section-title expect__title">
            {title}
          </h2>
          {subTitle ? <h3 className="dev-ops-offer__subtitle" dangerouslySetInnerHTML={{ __html: subTitle }} /> : ''}
          <div className="expect__content">
            <div className="expect__items__container">
              {items.map((item, index) => (
                <div key={item.id} className="expect__item">
                  <p className="expect__item__step-mobile">{index + 1}</p>
                  <h3 className="expect__item__title">{item.paragraphTitle}</h3>
                  <div
                    className="expect__item__text"
                    dangerouslySetInnerHTML={{ __html: item.paragraphText }}
                  ></div>
                  <img
                    className="expect__item-icon"
                    src={numberImages[index]}
                    alt={`Section number ${index + 1}`}
                  />
                </div>
              ))}
            </div>
            <img
              className="expect__icon"
              src={icon.url}
              alt={title}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
