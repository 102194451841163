import React from "react"

export const SimpleContentSection = ({
  title,
  paragraphs,
  summationBlockHeader,
  contentBefore,
  items,
  contentAfter,
  bgColor
}) => {
  return (
    <section style={{ backgroundColor: bgColor }} className={`consulting-digital-strategy__container`}>
      <div className="container">
        <div className="consulting-digital-strategy">
          <h2 className=" consulting__page__section-title consulting-digital-strategy__title">
            {title}
          </h2>
          <div
            className="consulting-digital-strategy__content-before"
          >
            {paragraphs.map((item) => (
              <>
                <h4 key={item.id}>{item.paragraphTitle}</h4>
                <div dangerouslySetInnerHTML={{ __html: item.paragraphText }} key={item.id} />
              </>
            ))}
            <h4>{summationBlockHeader}</h4>
          </div>
          {contentBefore ? (
            <div
              className="consulting-digital-strategy__content-after"
              dangerouslySetInnerHTML={{ __html: contentBefore }}
            />
          ) : ''}
          <div className="consulting-digital-strategy__items">
            {items.map(item => (
              <div className="consulting-digital-strategy__item">
                <img
                  className="consulting-digital-strategy__item__icon"
                  src={item.icon.url}
                  alt={item.text}
                />
                <div className="consulting-digital-strategy__item__text" dangerouslySetInnerHTML={{ __html: item.text }} />

              </div>
            ))}
          </div>
          {contentAfter ? (
            <div
              className="consulting-digital-strategy__content-after"
              dangerouslySetInnerHTML={{ __html: contentAfter }}
            />
          ) : ''}
        </div>
      </div>
    </section>
  )
}