import React, { useMemo, useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import getDate from "../helpers/getDate"
import getReadTime from "../helpers/getReadTime"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Link from "../components/Link"
import ArticleContent from "../components/ArticleContent/ArticleContent"
import generateBlogDetails from "../helpers/blogDetails"
import useSiteMetadata from "../helpers/useSiteMetadata"
import useWindowWidth from "../helpers/useWindowWidth"
import { ArrowDownIcon } from "../components/Icons"
import { MoreInBlogSection } from "../components/ServicePageSections/MoreInBlog"


const plainText = html => {
  let text = html
  if (text) {
    text = text.replace(/<style([\s\S]*?)<\/style>/gi, "")
    text = text.replace(/<script([\s\S]*?)<\/script>/gi, "")
    text = text.replace(/<\/div>/gi, "\n")
    text = text.replace(/<\/li>/gi, "\n")
    text = text.replace(/<li>/gi, "  *  ")
    text = text.replace(/<\/ul>/gi, "\n")
    text = text.replace(/<\/p>/gi, "\n")
    text = text.replace(/<br\s*\/?>/gi, "\n")
    text = text.replace(/<[^>]+>/gi, "")
    text = text.replaceAll(/"/gi, "&#65282;")
    text = text.replaceAll(/'/gi, "&#39;")
  }

  return text
}

const Post = props => {
  const { width } = useWindowWidth()
  const [isArticleContentOpened, setIsArticleContentOpened] = useState(false)
  const { siteUrl } = useSiteMetadata()
  const lang = props.pageContext.locale
  const posts = props.data.post.nodes // there can be posts with same slug but different language so we use this approach
  const relatedPosts = props.data.related.nodes;
  const post = posts.find(post => post.locale === lang) // 2 languages = 2 posts
  // const differentLanguagePost = posts.find(post => post.locale !== lang);
  const { slug, title, articleDate, seo, image, author, categories, buttonCta, callToAction, content, summary } = post

  const textContentSections = useMemo(() => (
    content.filter(item => (item.id.startsWith('DatoCmsContentSection') && item.contentId))
  ), [content])

  const allTextContent = useMemo(() => {
    let allTextContent = '';
    content.filter(item => item.id.startsWith('DatoCmsContentSection')).forEach(section => {
      allTextContent += section.content;
    });
    return allTextContent;
  }
    , [textContentSections])



  const related = relatedPosts.filter(post =>
    post.locale === lang &&
    post.categories.some(postCategory =>
      categories.some(category => category.slug === postCategory.slug)
    ) &&
    post.slug !== slug
  ); const getAuthor = () => {
    const getDescription = author._allAuthorDescriptionLocales.find(
      obj => obj.locale === lang
    )
    const getName = author._allAuthorNameLocales.find(
      obj => obj.locale === lang
    )

    return {
      authorName: getName.value,
      authorDescription: getDescription.value,
    }
  }
  const { authorName, authorDescription } = getAuthor()
  const { authorUrl, authorImage } = author
  const blogUrl = `https://www.valudio.com/${lang === "de" ? "de/" : ""
    }blog/${slug.replace("//", "/")}`
  const breadcrumSeoItems = JSON.parse(
    JSON.stringify(`
        {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Blog",
                "item": "${siteUrl}/${lang === "de" ? "de/" : ""}blog/"
            },{
                "@type": "ListItem",
                "position": 2,
                "name": "${categories[0].name}",
                "item": "${siteUrl}/${lang === "de" ? "de/" : ""}blog/${categories[0].slug
      }/"
            },{
                "@type": "ListItem",
                "position": 3,
                "name": "${title}"
            }]
        }
    `)
  )
  const blogDetails = generateBlogDetails(
    title,
    image.gatsbyImageData,
    allTextContent ? allTextContent.length : 0,
    blogUrl,
    articleDate,
    seo?.description,
    plainText(allTextContent),
    authorName,
    authorUrl,
    breadcrumSeoItems
  )


  return (
    <Layout lang={lang} location={props.location} link="post" buttonCta={buttonCta} callToAction={callToAction}>
      <Seo
        title={seo?.title ?? ""}
        slug={`blog/${slug}`}
        description={seo?.description ?? ""}
      />
      <Helmet>
        <script type="application/ld+json">{JSON.parse(blogDetails)}</script>
        <script type="application/ld+json">{breadcrumSeoItems}</script>
      </Helmet>
      <article className="post">
        {width < 768 && (
          <div role="button"
            tabIndex={0}
            onKeyDown={(e) => { if (e.key === 'Enter' || e.key === ' ') setIsArticleContentOpened(!isArticleContentOpened); }}
            onClick={() => setIsArticleContentOpened(!isArticleContentOpened)} className="article__content">
            <h4>Article Content</h4>
            <ArrowDownIcon className={isArticleContentOpened ? 'opened' : undefined} />
          </div>
        )}
        <nav className="breadcrumb">
          <Link lang={lang} to="/blog/">
            Blog
          </Link>
          <span className="divider">|</span>
          <span>{title}</span>
        </nav>
        <div className="container">
          <h1
            className="post__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className="post__info">
            <span className="date">{getDate(articleDate, lang)}</span>
            <span className="read">
              {`${getReadTime(allTextContent)} ${lang === "de" ? "Minuten Lesezeit" : "Minutes Reading Time"
                }`}
            </span>
          </div>
          <div className="post__image">
            <GatsbyImage
              image={image.gatsbyImageData}
              title={image.alt}
              alt={image.alt}
            />
          </div>
        </div>
        <ArticleContent
          isArticleContentOpened={isArticleContentOpened}
          setIsArticleContentOpened={setIsArticleContentOpened}
          summary={summary}
          textContentSections={textContentSections}
          content={content}
          authorImage={authorImage}
          authorName={authorName}
          authorDescription={authorDescription}
          categories={categories}
          relatedBlogs={related}
          lang={lang}
        />
        <MoreInBlogSection title={lang === 'en' ? `Related blog's` : `Verwandte blogs`} blogs={related} lang={lang} />

        <div className="services-hero__particle" />
        <div className="services-hero__particle__gradient" />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query BlogSingleArticle($slug: String) {
    post: allDatoCmsBlogArticlesModel(filter: { slug: { eq: $slug }, published: {eq: true}  }) {
      nodes {
        slug
        locale
        title
        seo {
          description
          title
        }
        categories {
          id
          name
          slug
        }
        content {
          ... on DatoCmsCalloutSection {
            id
            title
            calloutContent
          }
          ... on DatoCmsContentSection {
            id
            content
            contentId
          }
          ... on DatoCmsCtaSection {
            id
            ctaText
            buttonName
            buttonLink
          }
          ... on DatoCmsQuoteSection {
            id
            author
            authorRole
            quoteContent
            authorImage {
              alt
              gatsbyImageData
            }
          }
        }
        articleDate
        author {
          _allAuthorNameLocales {
            value
            locale
          }
          _allAuthorDescriptionLocales {
            value
            locale
          }
          authorUrl
          authorImage {
            gatsbyImageData(width: 60, height: 60, layout: FIXED)
            alt
          }
        }
        description
        image {
          gatsbyImageData(width: 1120, height: 400, layout: FIXED)
          alt
        }
        buttonCta
        callToAction
        summary {
          items {
            title
            description
            id
          }
        }
      }
    }
    related: allDatoCmsBlogArticlesModel(
      filter: { published: {eq: true}  }
      sort: { fields: articleDate, order: DESC }
    ) {
      nodes {
        locale
        title
        seo {
          description
          title
        }
        content {
          ... on DatoCmsCalloutSection {
            id
            title
            calloutContent
          }
          ... on DatoCmsContentSection {
            id
            content
            contentId
          }
          ... on DatoCmsCtaSection {
            id
            ctaText
            buttonName
            buttonLink
          }
          ... on DatoCmsQuoteSection {
            id
            author
            authorRole
            quoteContent
            authorImage {
              alt
              gatsbyImageData
            }
          }
        }
        categories {
          id
          slug   
          name      
        }
        slug
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData( height: 200)
        }
        articleDate
      }
    }
  }
`

export default Post
