import React, { useState, useEffect, useMemo } from "react"
import { Link } from "react-scroll"
import { GatsbyImage } from "gatsby-plugin-image"
import { QuoteBlogSection } from "./components/QuoteBlogSection"
import { CTABlogSection } from "./components/CTABlogSection"
import { CalloutBlogSection } from "./components/CalloutBlogSection"
import { SummaryBlogSection } from "./components/SummaryBlogSection"


export const ArticleContent = ({
  content,
  textContentSections,
  authorImage,
  authorName,
  authorDescription,
  categories,
  lang,
  isArticleContentOpened,
  setIsArticleContentOpened,
  summary
}) => {


  const textSections = useMemo(() => {
    return summary.length > 0 ? [{ contentId: lang === 'en' ? 'Key insights from this article' : 'Die wichtigsten Erkenntnisse dieses Beitrags' }, ...textContentSections] : textContentSections
  }, [textContentSections, summary, lang])

  const [menuInViewport, setMenuInViewport] = useState(false)
  const [activeSection, setActiveSection] = useState(textSections[0])


  const isVisible = () => {
    const contentFromTop = document
      .querySelector(".post__content--main")
      .getBoundingClientRect().top

    return contentFromTop <= 40 ? true : false
  }

  const checkSection = () => {
    const content = document.querySelector(".post__content--main .content");
    const containers = [...content.querySelectorAll("section")];

    let firstPartiallyVisibleContainer = null;

    const container = containers.find((container, index) => {
      if (container.id) {
        const offset = 150;
        const fromTop = container.getBoundingClientRect().top;
        const height = container.offsetHeight;

        if ((fromTop + offset) < window.innerHeight && fromTop + height - offset > 0) {
          if (!firstPartiallyVisibleContainer) {
            firstPartiallyVisibleContainer = container;
          }

          if ((fromTop + offset) < window.innerHeight && fromTop + height - offset > 0) {
            return container;
          }
        }
      }
      return null;
    });

    return (container || firstPartiallyVisibleContainer) && (container || firstPartiallyVisibleContainer).id;
  };


  useEffect(() => {
    setActiveSection(textSections)
  }, [textSections])

  useEffect(() => {
    const checkScroll = () => {
      setMenuInViewport(isVisible())
      const activeSection = checkSection()
      if (activeSection) setActiveSection(activeSection)
    }

    document.addEventListener("scroll", checkScroll)
    return () => {
      document.removeEventListener("scroll", checkScroll)
    }
  }, [])

  const [containerLeft, setContainerLeft] = useState(0)
  const [fixedMenu, setFixedMenu] = useState(false)

  useEffect(() => {
    window.scrollBy(0, 1)
  }, [])

  useEffect(() => {
    if (menuInViewport) {
      const container = document.querySelector(".container")
      const containerLeft = container.getBoundingClientRect().left
      if (containerLeft === 0) {
        const style = window.getComputedStyle(container)
        const left = style.getPropertyValue("padding-left")
        const number = parseInt(left.replace("px", ""))
        setContainerLeft(number)
      } else {
        setContainerLeft(containerLeft + 20)
      }
      setFixedMenu(true)
    } else {
      setFixedMenu(false)
      setContainerLeft(0)
    }
  }, [menuInViewport])

  const sectionsDOM = textSections.length > 0 && (
    <div className={`post__content--cta ${!isArticleContentOpened && 'hidden'}`}>
      <ul
        className={`post__cta--list ${fixedMenu ? "fixed" : ""}`}
        style={{ left: `${containerLeft}px` }}
      >
        {textSections.map((section) => {
          return (
            <li
              className={`post__cta--item ${activeSection === section.contentId ? "active" : ""
                }`}
              key={section.contentId}
            >
              <Link onClick={() => setIsArticleContentOpened(false)}
                to={section.contentId} smooth offset={-105}>
                {section.contentId}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )

  const renderContent = () => {
    return content.map(section => {
      if (section.id.startsWith('DatoCmsContentSection')) {
        return (
          <section
            id={section.contentId || ''}
            key={section.id}
            dangerouslySetInnerHTML={{ __html: section.content }}
            className="content container"
          />
        );
      } else if (section.id.startsWith('DatoCmsQuoteSection')) {
        return (
          <QuoteBlogSection
            key={section.id}
            quoteData={section}
          />
        );
      } else if (section.id.startsWith('DatoCmsCtaSection')) {
        return (
          <CTABlogSection
            key={section.id}
            lang={lang}
            ctaData={section} />
        );
      } else if (section.id.startsWith('DatoCmsCalloutSection')) {
        return (
          <CalloutBlogSection
            key={section.id}
            calloutData={section} />
        )
      } else return null;

    });
  };


  return (
    <section className="post__content container">
      {sectionsDOM}
      <div
        className={`post__content--main ${textSections.length > 0 ? "with-sidebar" : ""
          }`}
      >
        <div className="content" >
          {!!summary.length && <SummaryBlogSection lang={lang} summaryData={summary} />}
          {renderContent()}
        </div>
        <div className="container">
          {categories.length > 0 &&
            <div className="blog-card__tags">
              {categories.map((category) => (
                <div className="blog-card__tag-item" key={category.id}>
                  {category.name}
                </div>
              ))}
            </div>}
          <div className="post__content--author">
            <div className="author__hr" />
            <div className="author__image">
              <GatsbyImage
                image={authorImage.gatsbyImageData}
                alt={authorImage.alt}
                title={authorImage.alt}
              />
            </div>
            <div className="author__content">
              <h6 className="author__name">Author: {authorName}</h6>
              <div
                className="author__description"
                dangerouslySetInnerHTML={{ __html: authorDescription }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ArticleContent
